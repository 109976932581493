p {
  margin: 0;
}

h1 {
  margin-bottom: 2.5rem;
  color: #202325;
  font-family: "Shopee Bold";
}

.spin-fallback {
  margin-top: 3.5rem;
  width: 100%;
  padding: 5rem;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.375rem;
  }

  button {
    font-size: 1em !important;
  }
}

@media screen and (max-width: 500px) {
  nav .content button:nth-child(2) {
    padding: 0 !important;
  }

  button {
    padding: 0.8rem 1.6rem !important;
  }
}

@primary-color: rgb(238, 77, 45);